.calendar-container {
  /* display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center; */
  height: calc(100vh - 65px);
  white-space: pre-wrap;
}

.fc {
  /* padding: 25px; */
  border-color: black;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;

}

.fc td {
  white-space: pre-wrap;
  width: 100px;
}

.fc-daygrid-day-frame {
  width: 100%;
}

.fc .fc-toolbar.fc-header-toolbar {
  padding: 10px 20px;
  margin-bottom: 0px;
  white-space: break-word;
}
.fc-event {
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
}

.fc-event:hover {
  color: white;
  background-color: #53abf3;
}

.fc-event-title {
  order: 2;
  white-space: break-word;
}

.fc-event-time {
  order: 1; /* порядок элемента - задайте нужное значение */
}

.fc-daygrid-event-dot {
  margin: 0;
  display: none;
}

.fc-daygrid-day-frame {
  overflow: auto;
  margin: 0;
  height: 100px;
}

.fc-scrollgrid-sync-inner::-webkit-scrollbar {
  background: transparent;
  width: 5px; /* Ширина скроллбара */
}

.fc-scrollgrid-sync-inner::-webkit-scrollbar-track {
  background: transparent;
}

.fc-scrollgrid-sync-inner::-webkit-scrollbar-thumb {
  background: #778d9f57;
  border-radius: 10px;
}
